function init_select2(container=null) {
    // Initiate select2 widget for fields forms, except for the ones with the class 'no-select2'
    // Accepts a jquery object as container to better select which are not yet select2, if not provided, it will search for all the fields in the page
    let selected;

    if (container != null) {
        selected = container.find('.custom-select').not('.no-select2');
    } else {
        selected = $('.custom-select').not('.no-select2');
    }

    selected.each(function() {
        if ($(this).is(":visible")) {
            $(this).select2();
        }
    });
}

$(function () {

    init_select2();

    // Hide DELETE button for inlineformset
    window.hide_first_delete_formset = function(labels) {
        labels.map(function(item) { $('#div_id_' + item + '-0-DELETE').hide(); })
    }

    // Add row to a inlineformset using the table layout
    window.add_row_inlineformset = function(labels, suffix, formset_id, css_class=null, obj=null) {
        console.log('Adding InlineFormset with add_row_inlineformset');
        labels.map(function(item){
            if( $('#div_id_' + item + '-0-' + suffix).length ) {
                var form_idx = $('#id_' + item + '-TOTAL_FORMS').val();
                var empty = $('.empty-form')
                if((css_class != null) && (obj != null)) { var empty = obj.closest('.'+css_class).find('.empty-form') }

                $('#' + formset_id +' tbody').append("<tr>" + empty.html().replace(/__prefix__/g, form_idx) + "</tr>");
                $('#id_' + item + '-TOTAL_FORMS').val(parseInt(form_idx) + 1);
            }
        });
        init_select2();
        init_flatpickr();
    }

    // Add row to a inlineformset NOT using the table layout
    window.add_row_formset = function(formset, helper) {
        console.log('Adding InlineFormset with add_row_formset (no select2)');
        if($('#id_' + formset + '-TOTAL_FORMS').val()) {
            var form_idx = $('#id_' + formset + '-TOTAL_FORMS').val();
            var empty = $('#' + helper).find('.empty-form');
            $('#' + helper).append("<div class='formset_item'>" + empty.html().replace(/__prefix__/g, form_idx) + "</div>");
            $('#id_' + formset + '-TOTAL_FORMS').val(parseInt(form_idx) + 1);
            window.show_add_replicate([formset]);
            init_select2();
            init_flatpickr();
        }
    }

    // Show or hide the 'Add replicate' button
    window.show_add_replicate = function(formsets){
        formsets.map(function(item){
            if( $('#id_' + item + '-TOTAL_FORMS').val() < $('#id_' + item + '-MAX_NUM_FORMS').val()){
                $("#add_"+item).show()
            }else{
                $("#add_"+item).hide()
            }
         })
    }

    // Add a counter at the end of a field label
    window.add_counter_to_field_label = function(css_class, target){
        $('.'+css_class).each(function(i, obj){
            var attr_label = target.replace(/[*]/g, i)
            var label = $('label[for="'+attr_label+'"]').text()

            if(label.trim().slice(-1) == '*'){ // For required fields
                var new_label = label.replace(/[*]/g," - "+(i+1)+"*")
            }else{
                var new_label = label + " - " + (i+1)
            }

            $('label[for="'+attr_label+'"]').text(new_label)
        })
    }

    //---------- Reagents Modal Functions  ----------//

    // Open modal
    $('#open_reagent_modal').on( "click", function() {
        $('#reagent-modal').modal('show');
    });

    // Reset form in modal
    window.clear_reagent_modal = function(event){
        $('#id_reagent').val(null).trigger('change')
        $('#id_lot').val(null)
        $('#id_supplier').val(null)
        $('#id_expiry_date').val(null)
        $('#reagent-lot-modal-errors').empty()

    }

    // Submit form in modal
    $('#submit_reagent_modal').on( "click", function() {
        if(!($('#id_reagent').val())){
            alert('ERROR - You must select a Reagent Disposable')
        }else if(!($('#id_lot').val())){
            alert('ERROR - You must provide a Lot input')
        }else{
            $.ajax({
                url: '/secure/admin/reagent-disposale-lot/ajax/create/',
                type: 'POST',
                data: $('#ajax-reagent-lot-form').serialize(),
                success: function(response) {
                    if (response.hasOwnProperty('errors')){
                        $('#reagent-lot-modal-errors').append(response['errors'])
                    }else{
                        var newOption = new Option(response.text, response.id, true, true);
                        $('#id_reagent_lots').append(newOption).trigger('change');
                        $('#close_reagent_modal').click()
                        clear_reagent_modal()
                    }
                },
                error: function(request, error){
                    console.log(error)
                }
            })
        }
    });

    //---------- Equipment Modal Functions  ----------//

    // Open modal
    $('#open_equipment_modal').on( "click", function() {
        $('#equipment-modal').modal('show');
    });

    // Reset form in modal
    window.clear_equipment_modal = function(event){
        $('#id_equipment').val(null).trigger('change')
        $('#id_serial_number').val(null)
        $('#equipment-modal-errors').empty()
    };

    // Reset form in modal
    $('#submit_equipment_modal').on( "click", function() {
        if(!($('#id_equipment').val())){
            alert('ERROR - You must select an Equipment')
        }else if(!($('#id_serial_number').val())){
            alert('ERROR - You must provide an Equipment ID')
        }else{
            $.ajax({
                url: '/secure/admin/equipment/ajax/create/',
                type: 'POST',
                data: $('#ajax-equipment-form').serialize(),
                success: function(response) {
                    if (response.hasOwnProperty('errors')){
                        $('#equipment-modal-errors').append(response['errors'])
                    }else{
                        var equipments = $('#formset_equipment').find('select');
                        equipments.each(function(index, obj){
                            // newOption must be create through the loop in order to populate every widget
                            var newOption = new Option(response.text, response.id, false, false);
                            obj.append(newOption);
                        });
                        $('#close_equipment_modal').click();
                        clear_equipment_modal()
                    }
                },
                error: function(request, error){
                    console.log(error)
                }
            })
        }
    });

})
